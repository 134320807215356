import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import canada from '../images/canada.png';
import usa from '../images/usa.png';
import kochi from '../images/kochi.png';

export const HeaderData = [{
  id: 1,
  heading: 'Office Locations',
  address: <FontAwesomeIcon icon="fa-solid fa-location-dot" />,
  email: <FontAwesomeIcon icon="fa-solid fa-envelope" />,
  phone: <FontAwesomeIcon icon="fa-solid fa-phone" />,
  image: canada,
  data: [
    {
      id: 1,
      location: <h2>Canada</h2>,
      address:
  <span>
    Suite 720, 2425 Matheson Blvd. E Mississauga Ontario
    <br />
    L4W 5K4
  </span>,
      phone: '+ 1 905-361-6467',
      email: 'sales@infenox.com',
      image: canada,
    },
    {
      id: 2,
      location: <h2>US</h2>,
      address: '80 Broad Street, 5th Floor, New York City, New York, 10004, United States of America',
      phone: '+1 212-837-7700',
      email: 'sales@infenox.com',
      image: usa,
    },
    {
      id: 3,
      location: <h2>India</h2>,
      address:
  <span>
    Wing - 1, 5th Floor, Jyothirmaya, Infopark Phase 2 SEZ, Kochi, Kerala
    <br />
    682 303
  </span>,
      phone: '+91 99006 00553',
      email: 'sales@infenox.com',
      image: kochi,
    },
  ],
}];

export const ExpertData = [{
  heading1: 'Speak to Our Experts',
  heading3: 'Get in Touch',
  buttonLabel: 'SUBMIT NOW',
  data: [{
    id: 1,
    label: 'Email',
    icon: <FontAwesomeIcon icon="fa-solid fa-envelope" />,
    text: 'sales@infenox.com',
  },
  {
    id: 2,
    label: 'Phone',
    icon: <FontAwesomeIcon icon="fa-solid fa-phone" />,
    text: '+91 99006 00553',
  },
  {
    id: 3,
    label: 'Address',
    icon: <FontAwesomeIcon icon="fa-solid fa-location-dot" />,
    text:
  <span>
    Wing - 1, 5th Floor, Jyothirmaya,
    <br />
    Infopark Phase 2 SEZ,
    <br />
    Kochi, Kerala, 682 303
  </span>,
  },
  ],
}];
