/* eslint-disable max-len */
import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { HeaderData } from '../../../data/contact-db';

export default function ContactHeader() {
  return (
    <section className="contact-header">
      <Container>
        <Row>
          <h2>
            {HeaderData[0].heading}
            {' '}
            <span className="infenox-text-highlight office-loc" />
          </h2>

          <Col className="col-12 col-md-6">

            <Row>

              {HeaderData[0].data.map(({
                id, location, address, phone, email, image,
              }) => (
                <Col key={id} className="col-12 col-md-12 contact-header-info">
                  <Row>
                    <Col className="col-3">
                      <img src={image} alt="Location" className="contact-header-info-bg" />

                    </Col>
                    <Col className="col-9">

                      <p>
                        {' '}
                        {location}
                        {HeaderData[0].address}
                        {' '}
                        {address}

                      </p>

                      <p>
                        {HeaderData[0].phone}
                        {' '}
                        {phone}

                      </p>

                      <p>
                        {HeaderData[0].email}
                        {' '}
                        {email}

                      </p>

                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="col-12 col-md-6 contact-header-map">
            <iframe className="iframe-box" src="https://www.google.com/maps/d/embed?mid=1n5dvWuZKGBe7mMj48rYMhuqiGomz-Hc&ehbc=2E312F" title="map" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
